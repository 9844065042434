import { Icons } from '@/assets';

type Props = {
  title: string;
  tag?: string;
  selected?: boolean;
  onClick?: () => void;
  titleIcon?: keyof typeof Icons;
  tagIcon?: keyof typeof Icons;
};

const SearchBarDropdownOption = (props: Props) => {
  const { title, tag, titleIcon, tagIcon, selected, onClick } = props;

  const TitleIcon = titleIcon && Icons[titleIcon];
  const TagIcon = tagIcon && Icons[tagIcon];

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.stopPropagation();
    if (onClick) onClick();
  };

  return (
    <div
      className={`pointer-events-auto group flex items-center h-7 my-1.5 px-2 border ${selected ? 'bg-qura-neutral-ghost border-gray-200' : 'border-transparent '} hover:bg-qura-neutral-ghost hover:border-gray-200 rounded-md cursor-pointer`}
      onClick={onClick && handleClick}>
      {TitleIcon && <TitleIcon className="w-3 h-4 text-qura-neutral-light" />}
      {tag && (
        <div className="flex min-w-20">
          <div
            className={`flex items-center text-xs bg-qura-neutral-ghost rounded px-2 border ${selected ? 'border-transparent' : 'border-gray-200 group-hover:border-transparent'}`}>
            {TagIcon && <TagIcon />}
            <p className="pl-1.5 py-.5">{tag}</p>
          </div>
          <div className="w-2" />
        </div>
      )}
      <p className="pl-2.5 flex-1 line-clamp-1 text-sm">{title}</p>
    </div>
  );
};

export default SearchBarDropdownOption;
