import { Excerpt } from '@/services/api/actions/useInDocumentSearch';
import { SearchParams, SearchResult } from '@/services/api/commonSchemas';
import { MAX_CHUNKS_PER_DOCUMENT, MAX_DOCUMENTS_PER_SEARCH } from '../../constants';
import { useSearchStore } from '../searchStore';
import { TagNode } from '@/services/api/actions/useAvailableFilterTags';

export const setQuery = (query: string) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.query = query;
      state.currentParams.searchMode.noAiFilter = false;
      state.inDocumentSearchQuery = query;
    },
    false,
    'searches/set-query',
  );
};

export const setParams = (params: SearchParams) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams = params;
    },
    false,
    'searches/set-params',
  );
};

export const setActiveDataRole = (dataRole: string) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.dataRole = dataRole;
    },
    false,
    'searches/set-params',
  );
};

export const loadOldSearch = (
  searchId: string,
  result: SearchResult,
  submittedParams: SearchParams,
) => {
  useSearchStore.setState(
    (state) => {
      state.crossDocumentSearches[searchId] = {
        ws: null,
        submittedParams,
        results: result,
      };
    },
    false,
    'searches/set-params',
  );
};

export const setFilterAfterYear = (startYear: number | null) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.afterDate = startYear ? [startYear, 1, 1] : null;
    },
    false,
    'searches/set-filter-start-year',
  );
};

export const setFilterBeforeYear = (endYear: number | null) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.beforeDate = endYear ? [endYear, 12, 31] : null;
    },
    false,
    'searches/set-filter-end-year',
  );
};

export const resetFilter = () => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = true;
      state.currentParams.filter = {
        tagIds: [],
        documentIds: [],
        afterDate: null,
        beforeDate: null,
      };
    },
    false,
    'searches/reset-filter',
  );
};

export const clearParams = () => {
  useSearchStore.setState(
    (state) => {
      state.currentParams = {
        query: '',
        maxChunksPerDocument: MAX_CHUNKS_PER_DOCUMENT,
        maxDocuments: MAX_DOCUMENTS_PER_SEARCH,
        searchMode: {
          params: {},
          noAiFilter: false,
          noChunks: false,
        },
        filter: {
          tagIds: [],
          documentIds: [],
          afterDate: null,
          beforeDate: null,
        },
        dataRole: state.currentParams.dataRole,
      };
    },
    false,
    'searches/clear-params',
  );
};

export const addTags = (tagIds: string[]) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.tagIds.push(...tagIds);
    },
    false,
    'searches/add-filter-tags',
  );
};

export const toggleExclusiveTag = (tagId: string) => {
  useSearchStore.setState(
    (state) => {
      state.currentParams.searchMode.noAiFilter = false;
      if (state.currentParams.filter.tagIds.includes(tagId)) {
        state.currentParams.filter.tagIds = state.currentParams.filter.tagIds.filter(
          (id) => id !== tagId,
        );
      } else {
        state.currentParams.filter.tagIds = [tagId];
      }
    },
    false,
    'searches/toggle-exclusive-filter-tag',
  );
};

const getAllTagIds = (tagNode: TagNode): string[] => {
  let tagIds = [tagNode.tagId];
  for (const subNode of tagNode.subNodes) {
    tagIds = tagIds.concat(getAllTagIds(subNode));
  }
  return tagIds;
};

export const removeTags = (tagIds: Set<string> | string[]) => {
  tagIds = Array.isArray(tagIds) ? new Set(tagIds) : tagIds;
  useSearchStore.setState(
    (state) => {
      // if you remove a tag and no tag was selected, set all other tags as selected
      if (state.currentParams.filter.tagIds.length === 0) {
        state.currentParams.filter.tagIds = state.availableTags
          .map(getAllTagIds)
          .flatMap((tagIds) => tagIds);
      }

      state.currentParams.searchMode.noAiFilter = false;
      state.currentParams.filter.tagIds = state.currentParams.filter.tagIds.filter(
        (tagId) => !tagIds.has(tagId),
      );
    },
    false,
    'searches/remove-tags',
  );
};

export const setSearchMode = (searchMode: 'crossDocumentSearch' | 'inDocumentSearch') => {
  useSearchStore.setState(
    (state) => {
      state.searchMode = searchMode;
    },
    false,
    'searches/set-search-mode',
  );
};

export const setInDocumentSearchQuery = (query: string) => {
  useSearchStore.setState(
    (state) => {
      state.inDocumentSearchQuery = query;
    },
    false,
    'searches/set-in-document-search-query',
  );
};

export const setInDocumentSearchOverrides = (overrides: Record<string, Excerpt[]>) => {
  useSearchStore.setState(
    (state) => {
      state.inDocumentSearchOverrides = overrides;
    },
    false,
    'searches/set-in-document-search-overrides',
  );
};

export const setLastSearchID = (searchId: string) => {
  useSearchStore.setState(
    (state) => {
      state.lastSearchId = searchId;
    },
    false,
    'searches/set-last-search-id',
  );
};
