import { useSearchStore } from '../../state/searchStore';

export const FilterBadge = ({ searchId }: { searchId: string }) => {
  const submitted_filter = useSearchStore(
    (state) => state.crossDocumentSearches[searchId]?.submittedParams.filter,
  );

  const filtersApplied =
    (submitted_filter?.tagIds.length ?? 0) +
    (submitted_filter?.beforeDate || submitted_filter?.afterDate ? 1 : 0);

  if (!filtersApplied) return null;

  return (
    <div className="absolute right-2 -top-2 size-5 flex items-center justify-center rounded-full text-center text-xs bg-qura-neutral-jet text-white font-bold">
      {filtersApplied > 9 ? '!' : filtersApplied}
    </div>
  );
};
