import { create } from 'zustand';
import { devtools, subscribeWithSelector } from 'zustand/middleware';
import { immer } from 'zustand/middleware/immer';
import { MAX_CHUNKS_PER_DOCUMENT, MAX_DOCUMENTS_PER_SEARCH } from '../constants';
import { SearchParams, SearchResult } from '@/services/api/commonSchemas';
import { AppError } from '@/common/ErrorMessage/ErrorMessage';
import { Excerpt } from '@/services/api/actions/useInDocumentSearch';
import { TagNode } from '@/services/api/actions/useAvailableFilterTags';

interface SearchState {
  availableTags: TagNode[];
  currentParams: SearchParams;
  crossDocumentSearches: Record<
    string, // searchId
    {
      ws: WebSocket | null;
      results: SearchResult | null;
      submittedParams: SearchParams;
    }
  >;
  isCreatingSearch: boolean;
  searchIdHistory: string[];
  lastSearchId: string | null;
  error: AppError | null;
  searchMode: 'crossDocumentSearch' | 'inDocumentSearch';
  inDocumentSearchQuery: string;
  inDocumentSearchOverrides: Record<string, Excerpt[]>;
}

const initialState: SearchState = {
  availableTags: [],
  currentParams: {
    query: '',
    maxChunksPerDocument: MAX_CHUNKS_PER_DOCUMENT,
    maxDocuments: MAX_DOCUMENTS_PER_SEARCH,
    searchMode: {
      params: {},
      noChunks: false,
      noAiFilter: false,
    },
    filter: {
      tagIds: [],
      documentIds: [],
      afterDate: null,
      beforeDate: null,
    },
    dataRole: null,
  },
  searchMode: 'crossDocumentSearch',
  inDocumentSearchQuery: '',
  isCreatingSearch: false,
  searchIdHistory: [],
  lastSearchId: null,
  error: null,
  crossDocumentSearches: {},
  inDocumentSearchOverrides: {},
};

export const useSearchStore = create<SearchState>()(
  devtools(
    immer(
      subscribeWithSelector(() => ({
        ...initialState,
      })),
    ),
  ),
);
