import { useQuery } from '@tanstack/react-query';
import { doc, getDoc } from 'firebase/firestore';
import { z } from 'zod';
import { db } from '../../firebase/firebaseConfig';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { alertError } from '@/utils/alertError';
import { useSearchStore } from '@/features/Search/state/searchStore';

export type TagNode = {
  tagId: string;
  label: string;
  description: string | null;
  weight: number;
  subNodes: TagNode[];
};

const tagNodeSchema = z
  .object({
    tag_id: z.string(),
    label: z.string(),
    description: z.string().nullable().default(null),
    weight: z.number().int(),
    sub_nodes: z
      .lazy(() => tagNodeSchema.array())
      .nullable()
      .default([]),
  })
  .transform(convertSnakeToCamelCaseObject) as unknown as z.ZodType<TagNode>;

const availableFiltersSchema = z
  .object({
    tag_nodes: tagNodeSchema.array(),
  })
  .transform(convertSnakeToCamelCaseObject);

export type AvailableFilters = z.infer<typeof availableFiltersSchema>;

async function getAvailableFilterTagsRequest(dataRole?: string): Promise<AvailableFilters> {
  if (!dataRole) {
    return { tagNodes: [] };
  }

  try {
    const docRef = doc(db, 'dataRoles', dataRole);
    const document = await getDoc(docRef);
    if (!document.exists()) {
      throw new Error('Data role document does not exist');
    }

    const data = document.data();
    const parsedTags = availableFiltersSchema.parse(data.tag_tree);
    useSearchStore.setState({ availableTags: parsedTags.tagNodes });
    return parsedTags;
  } catch (error) {
    alertError(error, { function: 'getAvailableFilterTagsRequest' }, { dataRole });
    throw error;
  }
}

export const useAvailableFilterTags = (dataRole?: string) => {
  return useQuery({
    queryKey: ['filter_tags', dataRole],
    queryFn: () => getAvailableFilterTagsRequest(dataRole),
    enabled: dataRole !== null,
  });
};
