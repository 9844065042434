import { AxiosRequestConfig } from 'axios';
import { REST_API_URL } from './constants';

export const getDocumentUrl = (documentId: string, documentUnitId: string): string => {
  return `${REST_API_URL}/database/${documentUnitId}/document/${documentId}`;
};

export const standardAxiosOptions = (token: string): AxiosRequestConfig => {
  const headers: AxiosRequestConfig['headers'] = {
    'Content-Type': 'application/json',
    Accept: 'application/json',
    Authorization: `Bearer ${token}`,
    'x-forwarded-authorization': `Bearer ${token}`,
  };

  return {
    timeout: 40000,
    headers,
  };
};
