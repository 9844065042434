import { useQuery } from '@tanstack/react-query';
import { doc, getDoc } from 'firebase/firestore';
import { z } from 'zod';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { alertError } from '@/utils/alertError';
import { db } from '@/services/firebase/firebaseConfig';

const availableDateRangeSchema = z
  .object({
    after_date: z.tuple([z.number().int(), z.number().int(), z.number().int()]),
    before_date: z.tuple([z.number().int(), z.number().int(), z.number().int()]),
  })
  .transform(convertSnakeToCamelCaseObject);

export type AvailableDateRange = z.infer<typeof availableDateRangeSchema>;

const getEarliestDate = (dates: number[][]) =>
  dates.sort((a, b) =>
    a[0] !== b[0] ? a[0] - b[0] : a[1] !== b[1] ? a[1] - b[1] : a[2] - b[2],
  )[0];

const getLatestDate = (dates: number[][]) =>
  dates.sort((a, b) =>
    b[0] !== a[0] ? b[0] - a[0] : b[1] !== a[1] ? b[1] - a[1] : b[2] - a[2],
  )[0];

async function getAvailableDateRangeRequest(
  dataRole: string,
  tagIds: string[] = [],
): Promise<AvailableDateRange> {
  try {
    const dataRoleDocRef = doc(db, 'cache', 'tagDateRange', 'dataRoles', dataRole);
    const document = await getDoc(dataRoleDocRef);

    if (!document.exists()) {
      throw new Error('Tag date range document does not exist');
    }

    const data = document.data();
    let startDates: number[][] = [];
    let endDates: number[][] = [];
    if (tagIds.length > 0) {
      startDates = tagIds.map((tagId) => data[tagId].start_date);
      endDates = tagIds.map((tagId) => data[tagId].end_date);
    } else {
      startDates = Object.values(data).map((tag) => tag.start_date);
      endDates = Object.values(data).map((tag) => tag.end_date);
    }

    const afterDate = getEarliestDate(startDates);
    const beforeDate = getLatestDate(endDates);

    return availableDateRangeSchema.parse({
      after_date: afterDate,
      before_date: beforeDate,
    });
  } catch (error) {
    alertError(error, { function: 'getAvailableDateRangeRequest' });
    throw error;
  }
}

export const useAvailableDateRange = (dataRole: string, tagIds: string[] = []) => {
  return useQuery({
    queryKey: ['filter_dates', dataRole, ...tagIds],
    queryFn: () => getAvailableDateRangeRequest(dataRole, tagIds),
  });
};
