import { useQuery } from '@tanstack/react-query';
import axios from 'axios';
import { z } from 'zod';
import { REST_API_URL } from '../constants';
import { standardAxiosOptions } from '../utils';
import { BBSchema } from '../commonSchemas';
import { getAuthToken } from '@/services/firebase/functions/getCurrentUser';
import { convertSnakeToCamelCaseObject } from '@/utils/snakeToCamelCase';
import { alertError } from '@/utils/alertError';

const legalRefSchema = z
  .object({
    document_id: z.string(),
    document_unit_id: z.string(),
    reference_type: z.string(),
    raw: z.string().nullable().default(null),
    local_bbs: z.array(BBSchema).nullable().default(null),
    outgoing: z.boolean().nullable().default(null),
    sections: z.array(z.string()).nullable().default(null),
  })
  .transform(convertSnakeToCamelCaseObject);

export type LegalRef = z.infer<typeof legalRefSchema>;

const pagePropertiesSchema = z
  .object({
    height: z.number(),
    width: z.number(),
    page_number: z.number(),
    references: z.array(legalRefSchema).nullable().default(null),
  })
  .transform(convertSnakeToCamelCaseObject);

export type PageProperties = z.infer<typeof pagePropertiesSchema>;

export const documentPropertiesSchema = z
  .object({
    scale_factor: z.number().default(1.2),
    file_size: z.number(),
    page_count: z.number(),
    document_unit_id: z.string(),
    page_properties: z.array(pagePropertiesSchema),
    legal_id: z.string().nullable().default(null),
    title: z.string(),
    year: z.number().int().nullable().default(null),
    month: z.number().int().nullable().default(null),
    day: z.number().int().nullable().default(null),
    language: z.string().nullable().default(null),
    document_status: z.string().nullable().default(null),
  })
  .transform(convertSnakeToCamelCaseObject);

export type DocumentProperties = z.infer<typeof documentPropertiesSchema>;

async function getDocumentProperties(
  documentId: string,
  documentUnitId?: string,
): Promise<DocumentProperties> {
  try {
    const token = await getAuthToken();
    const response = await axios.get(
      `${REST_API_URL}/database/document/${documentId}/properties?document_unit_id=${documentUnitId}`,
      standardAxiosOptions(token),
    );
    return documentPropertiesSchema.parse(response.data);
  } catch (error) {
    alertError(error, { function: 'getDocumentProperties' }, { documentId, documentUnitId });
    throw error;
  }
}

export const useDocumentProperties = (documentId: string, documentUnitId?: string) => {
  return useQuery({
    queryKey: ['document_properties', documentId, documentUnitId],
    queryFn: () => getDocumentProperties(documentId, documentUnitId),
    retry: false,
  });
};
