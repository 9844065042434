import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { useEffect, useMemo } from 'react';
import { useCallback, useState } from 'react';
import { ChunkPreviewColumn } from './ChunkPreview';
import { RelatedDocumentsColumn } from './RelatedDocs';
import NotFound from './404';
import Layout from '../../components/Layout/Layout';
import DocumentViewer from '../../components/DocumentViewer/DocumentViewer';
import { createNewSearch } from '../../state/actions/createNewSearch';
import { sendSearchParams } from '../../state/actions/sendSearchParams';
import { useSearchStore } from '../../state/searchStore';
import { setInDocumentSearchQuery, setSearchMode } from '../../state/actions/searchParams';
import { TestViewerPosition } from '../../components/TextViewer/TextViewer';
import { useDocumentProperties } from '@/services/api/actions/useDocumentProperties';
import { useInDocumentSearch } from '@/services/api/actions/useInDocumentSearch';

const DocumentPageGuard = () => {
  const navigate = useNavigate();
  const { documentId } = useParams();

  if (!documentId) {
    navigate('/');
    return <></>;
  }

  return <DocumentPage documentId={documentId} />;
};

const DocumentPage = ({ documentId }: { documentId: string }) => {
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const lastSearchId = useSearchStore((state) => state.lastSearchId);
  const urlOffset = searchParams.get('offset');
  const urlPageNumber = searchParams.get('pageNumber');
  const urlQuery = searchParams.get('query');
  const [localQuery, setLocalQuery] = useState(urlQuery || '');
  const stateQuery = useSearchStore((state) => state.inDocumentSearchQuery);
  const { data: documentProperties, isLoading: isDocumentPropertiesLoading } =
    useDocumentProperties(documentId);
  const { data: inDocumentSearchResults, isLoading: isInDocumentSearchResultsLoading } =
    useInDocumentSearch(documentId, localQuery, documentProperties?.documentUnitId);
  const [filePosition, setFilePosition] = useState<TestViewerPosition>({
    pageNumber: urlPageNumber ? parseInt(urlPageNumber) - 1 : 0,
    offset: urlOffset ? parseInt(urlOffset) : 0,
  });
  const [pageScrollPosition, setPageScrollPosition] = useState(filePosition.pageNumber);
  const notFound = useMemo(
    () => !documentProperties && !isDocumentPropertiesLoading,
    [documentProperties, isDocumentPropertiesLoading],
  );

  useEffect(() => {
    if (!notFound) {
      setSearchMode('inDocumentSearch');
    } else {
      setSearchMode('crossDocumentSearch');
    }
  }, [notFound]);

  // Update the search params when the state query changes
  useEffect(() => {
    if (stateQuery) {
      setSearchParams({ query: stateQuery }, { replace: true });
      setInDocumentSearchQuery(stateQuery);
      setLocalQuery(stateQuery);
    } else if (localQuery) {
      setSearchParams({ query: localQuery }, { replace: true });
      setInDocumentSearchQuery(localQuery);
    } else {
      setSearchParams({ query: '' }, { replace: true });
      setInDocumentSearchQuery('');
    }
  }, [stateQuery, searchParams, setSearchParams, urlQuery, localQuery]);

  // Update the page scroll position when the file position changes
  const handleFilePositionChange = useCallback((newPosition: TestViewerPosition) => {
    setPageScrollPosition(newPosition.pageNumber);
  }, []);

  const onSearch = () => {
    createNewSearch().then(({ searchId }) => {
      sendSearchParams(searchId);
      navigate(`/search/${searchId}`);
    });
  };

  return (
    <Layout
      query={stateQuery}
      onSearch={onSearch}
      showHelpButton={false}
      showBackButtonLink={lastSearchId ? `/search/${lastSearchId}` : '/'}
      leftContent={() =>
        !notFound &&
        documentProperties && (
          <ChunkPreviewColumn
            documentId={documentId}
            documentUnitId={documentProperties?.documentUnitId}
            inDocumentSearchResults={inDocumentSearchResults || undefined}
            loading={isInDocumentSearchResultsLoading}
            setFilePosition={setFilePosition}
          />
        )
      }
      rightContent={() =>
        !notFound &&
        documentProperties && <RelatedDocumentsColumn documentProperties={documentProperties} />
      }
      contentClassName="w-[calc(100%-700px)] max-w-[850px] min-w-[75%] lg:min-w-[600px]">
      {!notFound && (
        <DocumentViewer
          documentId={documentId}
          query={stateQuery}
          documentProperties={documentProperties}
          inDocumentSearchResults={inDocumentSearchResults || undefined}
          onFilePositionChange={handleFilePositionChange}
          setFilePosition={setFilePosition}
          pageScrollPosition={pageScrollPosition}
          filePosition={filePosition}
        />
      )}
      {notFound && <NotFound />}
    </Layout>
  );
};

export default DocumentPageGuard;
