import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { PDFPreview } from '../../components/PDFPreview/PDFPreview';
import { MAX_CHUNKS_PER_DOCUMENT } from '../../constants';
import { TestViewerPosition } from '../../components/TextViewer/TextViewer';
import { useSearchStore } from '../../state/searchStore';
import { FileModifications, PageModifications } from '@/services/api/commonSchemas';
import { InDocumentSearchResult } from '@/services/api/actions/useInDocumentSearch';

const LoadingChunk = () => {
  return <div className="relative loading-horizontal border-none w-full h-36 rounded-md"></div>;
};

type ChunkPreviewProps = {
  documentId: string;
  documentUnitId: string;
  pageNumber: number;
  pageModifications: PageModifications;
  offset: number;
  setFilePosition: (position: TestViewerPosition) => void;
};
const ChunkPreview = ({
  documentId,
  documentUnitId,
  pageNumber,
  pageModifications,
  offset,
  setFilePosition,
}: ChunkPreviewProps) => {
  const [hasLoaded, setHasLoaded] = useState(false);

  const fileModifications: FileModifications = useMemo(() => {
    return {
      pageOverrides: [pageModifications],
      pageSelection: [pageNumber],
    };
  }, [pageModifications, pageNumber]);

  return (
    <div
      className="flex flex-col items-center justify-between cursor-pointer flex-shrink-0 px-1 gap-2"
      onClick={() => setFilePosition({ pageNumber, offset })}>
      <PDFPreview
        noTextLayer
        showPageNumber
        hasLoaded={hasLoaded}
        onLoad={() => setHasLoaded(true)}
        documentId={documentId}
        documentUnitId={documentUnitId}
        fileModifications={fileModifications}
        className="rounded shadow-qura p-1"
        loadingLogoHeight={60}
        width={150}
      />
    </div>
  );
};

type ChunkPreviewColumnProps = {
  documentId: string;
  documentUnitId: string;
  inDocumentSearchResults?: InDocumentSearchResult;
  loading: boolean;
  setFilePosition: (position: TestViewerPosition) => void;
};
export const ChunkPreviewColumn = ({
  documentId,
  documentUnitId,
  inDocumentSearchResults,
  loading,
  setFilePosition,
}: ChunkPreviewColumnProps) => {
  const { t } = useTranslation();
  const inDocumentSearchOverrides = useSearchStore((state) => state.inDocumentSearchOverrides);

  const sortedExcerpts = useMemo(() => {
    const overrides = inDocumentSearchOverrides[documentId] || [];
    const excerpts = inDocumentSearchResults?.excerpts || [];

    const mergedExcerpts = [...overrides];

    excerpts.forEach((excerpt) => {
      if (!mergedExcerpts.some((e) => e.pageNumber === excerpt.pageNumber)) {
        mergedExcerpts.push(excerpt);
      }
    });

    return mergedExcerpts.sort((a, b) => a.pageNumber - b.pageNumber);
  }, [inDocumentSearchResults?.excerpts, inDocumentSearchOverrides, documentId]);

  return (
    <div className="flex flex-col items-stretch h-full">
      <div className="flex flex-col w-filter flex-shrink overflow-hidden bg-white shadow-qura rounded-md">
        <div className="border-b border-gray-200 text-sm text-center py-5">
          <p>{t('searchDocumentPage.title')}</p>
        </div>
        <div className="px-12 pt-10 pb-12 flex flex-col gap-8 overflow-y-auto items-stretch">
          {sortedExcerpts.map(
            (excerpt) =>
              excerpt.fileModifications.pageOverrides &&
              excerpt.fileModifications.pageOverrides.length > 0 && (
                <ChunkPreview
                  documentId={documentId}
                  documentUnitId={documentUnitId}
                  pageNumber={excerpt.pageNumber}
                  pageModifications={excerpt.fileModifications.pageOverrides[0]}
                  offset={excerpt.offset}
                  key={excerpt.pageNumber}
                  setFilePosition={setFilePosition}
                />
              ),
          )}
          {loading &&
            Array(MAX_CHUNKS_PER_DOCUMENT - sortedExcerpts.length)
              .fill(undefined)
              .map((_, index) => <LoadingChunk key={`loading-${index}`} />)}
        </div>
      </div>
    </div>
  );
};
