import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDocumentName } from '@/services/api/actions/useDocumentName';
import { Icons } from '@/assets';
import { DocumentProperties } from '@/services/api/actions/useDocumentProperties';

const RelatedDocEntry = ({
  documentId,
  documentUnitId,
}: {
  documentId: string;
  documentUnitId?: string;
}) => {
  const navigate = useNavigate();
  const { data: documentName } = useDocumentName(documentId, documentUnitId);

  return (
    <li
      key={documentId}
      className="group flex items-start px-2 py-1.5 border border-transparent hover:bg-qura-neutral-ghost hover:border-gray-200 rounded-md cursor-pointer"
      onClick={() => navigate(`/document/${documentId}`)}>
      <Icons.Document className="w-3 h-4 mt-1 text-qura-neutral-light" />
      <div className="flex flex-col pl-2 flex-1">
        <p className="line-clamp-1 text-sm">{documentName?.legalId}</p>
        <p className="line-clamp-1 text-xs">{documentName?.title}</p>
      </div>
    </li>
  );
};

type RelatedDocsProps = {
  documentProperties: DocumentProperties;
};
export const RelatedDocumentsColumn = ({ documentProperties }: RelatedDocsProps) => {
  const { t } = useTranslation();
  const cited_docs = documentProperties.pageProperties
    .map((page) => page.references)
    .flat()
    .filter((reference) => reference?.referenceType === 'mentioned' && reference?.outgoing);
  const cited_by_docs = documentProperties.pageProperties
    .map((page) => page.references)
    .flat()
    .filter((reference) => reference?.referenceType === 'mentioned' && !reference?.outgoing);

  return (
    <div className="flex flex-col items-stretch h-full">
      <div className="flex flex-col w-[300px] flex-shrink overflow-hidden bg-white shadow-qura rounded-md">
        <div className="border-b border-gray-200 text-sm text-center py-5">
          <p>{t('relatedDocuments.title')}</p>
        </div>
        <div className="px-6 pt-6 pb-8 flex flex-col gap-8 overflow-y-auto">
          <div>
            <h2 className="text-lg mb-4 pb-2 border-b border-gray-200">
              {t('relatedDocuments.citedDocuments')}
            </h2>
            <ul className="space-y-2">
              {cited_docs.map((doc, index) => (
                <RelatedDocEntry
                  key={index}
                  documentId={doc?.documentId ?? ''}
                  documentUnitId={doc?.documentUnitId}
                />
              ))}
            </ul>
          </div>
          <div>
            <h2 className="text-lg mb-4 pb-2 border-b border-gray-200">
              {t('relatedDocuments.citedByDocuments')}
            </h2>
            <ul className="space-y-2">
              {cited_by_docs.map((doc, index) => (
                <RelatedDocEntry
                  key={index}
                  documentId={doc?.documentId ?? ''}
                  documentUnitId={doc?.documentUnitId}
                />
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
