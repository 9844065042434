import { signOut } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import posthog from 'posthog-js';
import { POSTHOG_EVENT } from '@/services/posthog/events';
import { alertError } from '../../../utils/alertError';

export async function logout() {
  try {
    posthog.capture(POSTHOG_EVENT.LOGOUT);
    await signOut(auth);
  } catch (error) {
    alertError(error, { function: 'logout' });
  }
}
