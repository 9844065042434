import { useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SearchBarDropdown } from './SearchBarDropdown';
import { SearchBarInput } from './SearchBarInput';
import {
  setInDocumentSearchOverrides,
  setInDocumentSearchQuery,
  setQuery as setSearchQuery,
} from '../../state/actions/searchParams';
import { useSearchStore } from '../../state/searchStore';
import { useDocumentSuggestions } from '@/services/api/actions/useDocumentSuggestions';
import { useRecentSearches } from '@/services/firebase/functions/getRecentSearches';

type Props = {
  initialQuery?: string;
  onSearch: () => void;
  onOpenHistoryModal: () => void;
};

export const SearchBar = (props: Props) => {
  const { initialQuery, onSearch, onOpenHistoryModal } = props;
  const { t } = useTranslation();
  const [inFocus, setInFocus] = useState(false);
  const [query, setQuery] = useState(initialQuery || '');
  const textareaRef = useRef<HTMLTextAreaElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);
  const activeDataRole = useSearchStore((state) => state.currentParams.dataRole);
  const searchMode = useSearchStore((state) => state.searchMode);
  const { data: recentSearches } = useRecentSearches(5);
  const { data: documentSuggestions } = useDocumentSuggestions(query, activeDataRole || undefined);
  const showDropdown = useMemo(
    () =>
      inFocus &&
      searchMode === 'crossDocumentSearch' &&
      (query.trim().length === 0
        ? recentSearches && recentSearches.recent.length > 0
        : documentSuggestions && documentSuggestions.completions.length > 0),
    [inFocus, searchMode, query, recentSearches, documentSuggestions],
  );

  const onFocus = () => {
    setInFocus(true);
  };

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (!containerRef.current?.contains(event.target as Node)) {
        setInFocus(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  useEffect(() => {
    setQuery(initialQuery || '');
  }, [initialQuery]);

  const onSearchClick = () => {
    console.log(query);
    setInDocumentSearchQuery(query);
    setInDocumentSearchOverrides({});
    if (searchMode === 'crossDocumentSearch') {
      setSearchQuery(query);
      onSearch();
    }
    textareaRef.current?.blur();
  };

  return (
    <div className="relative" ref={containerRef}>
      <div
        className={`flex flex-col w-full px-5 py-4 rounded-t-[28px] text-qura-neutral-jet bg-white text-sm shadow-qura z-20 ${showDropdown ? '' : 'rounded-b-[28px]'}`}>
        <SearchBarInput
          searchMode={searchMode}
          onFocus={onFocus}
          query={query}
          queryChanged={setQuery}
          onSearchClick={onSearchClick}
          placeholder={t('searchBar.placeholder')}
        />
      </div>
      {showDropdown && recentSearches && documentSuggestions && (
        <div className="absolute w-full z-10">
          <SearchBarDropdown
            query={query}
            onOpenHistoryModal={onOpenHistoryModal}
            recentSearches={recentSearches}
            documentSuggestions={documentSuggestions}
          />
        </div>
      )}
    </div>
  );
};
