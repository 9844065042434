import { sendPasswordResetEmail } from 'firebase/auth';
import { auth } from '../firebaseConfig';
import { alertError } from '@/utils/alertError';

export async function forgotPassword(email: string) {
  try {
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    alertError(
      error,
      {
        function: 'forgotPassword',
        message: 'Failed to send forgot password email with Firebase.',
      },
      { email },
    );
    throw error;
  }
}
