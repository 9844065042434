import { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import { BB } from '@/services/api/commonSchemas';
import { useDocumentName } from '@/services/api/actions/useDocumentName';
import { Icons } from '@/assets';

export const TextHighlight = ({
  onClick,
  onHoverStateChange,
  color = 'rgba(217, 255, 217, 1)',
  rects,
}: {
  onClick?: () => void;
  onHoverStateChange?: (isHovered: boolean) => void;
  color?: string;
  rects: BB[];
}) => {
  return (
    <>
      {rects.map((rect, index) => (
        <div
          key={index}
          onClick={onClick}
          onMouseEnter={() => onHoverStateChange?.(true)}
          onMouseLeave={() => onHoverStateChange?.(false)}
          style={{
            cursor: 'default',
            userSelect: 'none',
            pointerEvents: 'none',
            position: 'absolute',
            zIndex: 100,
            mixBlendMode: 'multiply',
            background: color,
            top: rect.y0,
            left: rect.x0,
            width: rect.x1 - rect.x0,
            height: rect.y1 - rect.y0,
          }}
        />
      ))}
    </>
  );
};

export const KeywordHighlight = ({
  onClick,
  onHoverStateChange,
  color = 'rgba(170, 255, 170, 1)',
  borderColor = 'rgba(120, 255, 120, 1)',
  rects,
  borderWidth = 1.2,
}: {
  onClick?: () => void;
  onHoverStateChange?: (isHovered: boolean) => void;
  color?: string;
  borderColor?: string;
  rects: BB[];
  borderWidth?: number;
}) => {
  return (
    <>
      {rects.map((rect, index) => (
        <div
          key={index}
          onClick={onClick}
          onMouseEnter={() => onHoverStateChange?.(true)}
          onMouseLeave={() => onHoverStateChange?.(false)}
          style={{ position: 'absolute', cursor: 'pointer' }}>
          {/* Top border */}
          <div
            key={`${index}-top`}
            style={{
              cursor: 'default',
              userSelect: 'none',
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 101,
              mixBlendMode: 'multiply',
              background: borderColor,
              top: rect.y0,
              left: rect.x0,
              width: rect.x1 - rect.x0,
              height: borderWidth,
            }}
          />
          {/* Bottom border */}
          <div
            key={`${index}-bottom`}
            style={{
              cursor: 'default',
              userSelect: 'none',
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 101,
              mixBlendMode: 'multiply',
              background: borderColor,
              top: rect.y1 - borderWidth,
              left: rect.x0,
              width: rect.x1 - rect.x0,
              height: borderWidth,
            }}
          />
          {/* Left border */}
          <div
            key={`${index}-left`}
            style={{
              cursor: 'default',
              userSelect: 'none',
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 101,
              mixBlendMode: 'multiply',
              background: borderColor,
              top: rect.y0 + borderWidth,
              left: rect.x0,
              width: borderWidth,
              height: rect.y1 - rect.y0 - 2 * borderWidth,
            }}
          />
          {/* Right border */}
          <div
            key={`${index}-right`}
            style={{
              cursor: 'default',
              userSelect: 'none',
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 101,
              mixBlendMode: 'multiply',
              background: borderColor,
              top: rect.y0 + borderWidth,
              left: rect.x1 - borderWidth,
              width: borderWidth,
              height: rect.y1 - rect.y0 - 2 * borderWidth,
            }}
          />
          {/* Highlight in the middle */}
          <div
            key={`${index}-middle`}
            style={{
              cursor: 'default',
              userSelect: 'none',
              pointerEvents: 'none',
              position: 'absolute',
              zIndex: 100,
              mixBlendMode: 'multiply',
              background: color,
              top: rect.y0 + borderWidth,
              left: rect.x0 + borderWidth,
              width: rect.x1 - rect.x0 - 2 * borderWidth,
              height: rect.y1 - rect.y0 - 2 * borderWidth,
            }}
          />
        </div>
      ))}
    </>
  );
};

const ReferencePopup = ({
  documentId,
  coordinates,
  onMouseEnter,
  onMouseLeave,
}: {
  documentId: string;
  coordinates: { x: number; y: number };
  onMouseEnter: () => void;
  onMouseLeave: () => void;
}) => {
  const { data: documentName } = useDocumentName(documentId);
  const [copied, setCopied] = useState(false);
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');

  const handleCopy = () => {
    if (documentName?.legalId) {
      navigator.clipboard.writeText(documentName.legalId);
      setCopied(true);
      setTimeout(() => setCopied(false), 2000);
    }
  };

  const handleLegalIdClick = (e: React.MouseEvent) => {
    e.stopPropagation();
    const redirectUrl = `${window.location.origin}/document/${documentId}?query=${query}`;
    window.open(redirectUrl, '_blank');
  };

  return (
    <div
      className="absolute z-[200] bg-white p-2 rounded-md shadow-md max-w-[300px] w-fit border border-gray-300"
      style={{
        top: coordinates.y,
        left: coordinates.x,
        transform: 'translate(-50%, -100%)',
      }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}>
      {documentName?.legalId && (
        <div className="flex items-center justify-between">
          <p
            className="text-sm font-medium cursor-pointer hover:underline pr-2"
            onClick={handleLegalIdClick}>
            {documentName?.legalId}
          </p>
          <button
            onClick={handleCopy}
            className="justify-center items-center h-6 w-6 border border-gray-300 bg-white rounded-md p-1">
            {copied ? (
              <Icons.Checked className="w-3 h-3 filter invert" />
            ) : (
              <Icons.Copy className="w-3.5 h-3.5" />
            )}
          </button>
        </div>
      )}
      <p className="text-xs text-gray-500 text-ellipsis line-clamp-3">{documentName?.title}</p>
    </div>
  );
};

export const LinkHighlight = ({
  onHoverStateChange,
  color = 'rgba(0, 0, 255, 1)',
  hoverColor = 'rgba(240, 240, 200, 1)',
  rects,
  documentId,
}: {
  onClick?: () => void;
  onHoverStateChange?: (isHovered: boolean) => void;
  color?: string;
  hoverColor?: string;
  rects: BB[];
  documentId: string;
}) => {
  const [searchParams] = useSearchParams();
  const query = searchParams.get('query');
  const [isHovered, setIsHovered] = useState(false);

  const redirectUrl = `${window.location.origin}/document/${documentId}?query=${query}`;

  const [closeTimeout, setCloseTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleMouseEnter = () => {
    if (closeTimeout) {
      clearTimeout(closeTimeout);
      setCloseTimeout(null);
    }
    setIsHovered(true);
    onHoverStateChange?.(true);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setIsHovered(false);
      onHoverStateChange?.(false);
    }, 350);
    setCloseTimeout(timeout);
  };

  return (
    <>
      {rects.map((rect, index) => (
        <div
          key={index}
          onClick={() => window.open(redirectUrl, '_blank')}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          style={{
            cursor: 'pointer',
            userSelect: 'none',
            position: 'absolute',
            zIndex: 100,
            mixBlendMode: isHovered ? 'multiply' : 'screen',
            background: isHovered ? hoverColor : color,
            top: rect.y0,
            left: rect.x0,
            width: rect.x1 - rect.x0,
            height: rect.y1 - rect.y0,
          }}
        />
      ))}
      {isHovered && (
        <ReferencePopup
          documentId={documentId}
          coordinates={{
            x: rects[0].x0 + (rects[0].x1 - rects[0].x0) / 2,
            y: rects[0].y0 - 15,
          }}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        />
      )}
    </>
  );
};
