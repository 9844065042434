import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import DownloadButton from './DownloadButton';
import ZoomInput from './ZoomInput';
import Tag from '../Tag/Tag';
import { TestViewerPosition } from '../TextViewer/TextViewer';
import { Icons } from '@/assets';
import { DocumentProperties } from '@/services/api/actions/useDocumentProperties';
import { FileModifications } from '@/services/api/commonSchemas';

const PageInput = ({
  pageCount,
  pageScrollPosition,
  setFilePosition,
}: {
  pageCount: number;
  pageScrollPosition: number;
  setFilePosition: (position: TestViewerPosition) => void;
}) => {
  const [inputValue, setInputValue] = useState(pageScrollPosition + 1);

  useEffect(() => {
    setInputValue(pageScrollPosition + 1);
  }, [pageScrollPosition]);

  const handleInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newPage = parseInt(event.target.value, 10);
    setInputValue(newPage);
  };

  const handleInputBlur = () => {
    let newPage = inputValue - 1;
    if (isNaN(newPage) || newPage < 0) {
      newPage = 0;
    } else if (pageCount && newPage >= pageCount) {
      newPage = pageCount - 1;
    }
    setFilePosition({ pageNumber: newPage, offset: 5 });
  };

  const handleInputKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.currentTarget.blur();
    }
  };

  return (
    <div className="flex flex-shrink-0 items-center">
      <input
        type="number"
        className="w-7 text-xs rounded text-center [appearance:textfield] [&::-webkit-outer-spin-button]:appearance-none [&::-webkit-inner-spin-button]:appearance-none focus:outline-none ring-1 ring-qura-neutral-silver hover:ring-qura-neutral-jet focus:ring-qura-neutral-balanced mr-2 "
        min={1}
        max={pageCount}
        value={inputValue}
        onChange={handleInputChange}
        onBlur={handleInputBlur}
        onKeyDown={handleInputKeyDown}
        data-np-intersection-state="hidden"
        autoComplete="off"
      />
      <div className="flex items-center">
        <span className="text-xs text-qura-neutral-jet">/</span>
        <span className="text-xs text-qura-neutral-jet m-2">{pageCount}</span>
      </div>
    </div>
  );
};

type DocumentViewerTopBarProps = {
  scale: number;
  setScale: (value: number) => void;
  documentProperties?: DocumentProperties;
  documentId: string;
  documentUnitId?: string;
  fileModifications?: FileModifications;
  pageScrollPosition: number;
  setFilePosition: (position: TestViewerPosition) => void;
};

export const DocumentViewerTopBar = ({
  scale,
  setScale,
  documentProperties,
  documentId,
  documentUnitId,
  fileModifications,
  pageScrollPosition,
  setFilePosition,
}: DocumentViewerTopBarProps) => {
  const { t } = useTranslation();
  const [isExpanded, setIsExpanded] = useState(false);

  const legalId = documentProperties?.legalId ?? '-';
  const title = documentProperties?.title ?? '-';

  const toggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  return (
    <div className="flex flex-col border-b px-4 py-3">
      <div className="flex justify-between items-center mb-2">
        <div className="flex items-center w-1/3">
          <Tag className="flex overflow-hidden flex-shrink md">
            <Icons.Book className="mr-2 mb-[1px] flex-shrink-0" />
            <p
              className="font-medium overflow-hidden text-ellipsis whitespace-nowrap flex-shrink"
              title={legalId}>
              {legalId}
            </p>
          </Tag>
        </div>
        {documentProperties ? (
          <div className="flex items-center justify-center w-1/3">
            <PageInput
              pageCount={documentProperties.pageCount}
              pageScrollPosition={pageScrollPosition}
              setFilePosition={setFilePosition}
            />
          </div>
        ) : (
          <p className="text-sm animate-pulse w-1/3 text-center">{t('common.loading')}</p>
        )}
        <div className="flex items-center justify-end gap-4 w-1/3">
          <ZoomInput scale={scale} setScale={setScale} />
          <DownloadButton
            documentId={documentId}
            documentUnitId={documentUnitId}
            fileModifications={fileModifications}
            identifier={documentProperties?.legalId ?? documentProperties?.title ?? ''}
          />
        </div>
      </div>
      <div className="overflow-hidden relative">
        <p
          className={`text-sm text-qura-neutral-jet ${isExpanded ? '' : 'truncate'} pr-6`}
          title={title}
          onClick={toggleExpand}>
          {title}
        </p>
        <button
          onClick={toggleExpand}
          className="absolute right-0 top-1/2 transform -translate-y-1/2 text-qura-neutral-jet hover:text-qura-neutral-balanced"
          aria-label={isExpanded ? 'Collapse title' : 'Expand title'}>
          <Icons.Arrow
            className={`w-3 h-3 transition-transform ${isExpanded ? 'rotate-180' : ''}`}
          />
        </button>
      </div>
    </div>
  );
};
