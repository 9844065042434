import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import SearchBarDropdownOption from './SearchBarDropdownOption';
import { setQuery } from '../../state/actions/searchParams';
import { Icons } from '@/assets';
import { RecentSearches } from '@/services/firebase/functions/getRecentSearches';
import { DocumentSuggestions } from '@/services/api/actions/useDocumentSuggestions';

const HistoryDropdown = ({
  onOpenHistoryModal,
  recentSearches,
}: {
  onOpenHistoryModal: () => void;
  recentSearches: RecentSearches;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleSearchClick = (searchId: string, query: string) => {
    setQuery(query);
    navigate(`/search/${searchId}`);
  };

  if (!recentSearches) return null;

  return (
    <div>
      <p className="text-tiny mt-2 text-qura-neutral-balanced">{t('searchBar.searchHistory')}</p>
      {recentSearches.recent.map((search) => (
        <SearchBarDropdownOption
          key={search.searchId}
          title={search.query}
          onClick={() => handleSearchClick(search.searchId, search.query)}
          titleIcon="Clock"
        />
      ))}
      <button
        onClick={onOpenHistoryModal}
        className="hover:underline font-normal text-xs text-qura-neutral-balanced">
        <div className="flex items-center">
          <p className="text-tiny">{t('searchBar.seeAll')}</p>
          <Icons.Arrow className="-rotate-90 ml-1 inline w-[12px]" />
        </div>
      </button>
    </div>
  );
};

const DocumentSuggestionsDropdown = ({
  documentSuggestions,
}: {
  documentSuggestions: DocumentSuggestions;
}) => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const handleDocumentClick = (documentId: string) => {
    navigate(`/document/${documentId}`);
  };

  if (!documentSuggestions || documentSuggestions.completions.length === 0) return null;

  return (
    <div>
      <p className="text-tiny mt-2 text-qura-neutral-balanced">{t('searchBar.documentMatch')}</p>
      {documentSuggestions.completions.map((completion, index) => (
        <SearchBarDropdownOption
          key={completion.document_id + index}
          title={completion.title}
          tag={completion.legal_id}
          tagIcon="Document"
          onClick={() => handleDocumentClick(completion.document_id)}
        />
      ))}
    </div>
  );
};

type SearchBarDropdownProps = {
  query: string;
  onOpenHistoryModal: () => void;
  recentSearches: RecentSearches;
  documentSuggestions: DocumentSuggestions;
};

export const SearchBarDropdown = ({
  query,
  onOpenHistoryModal,
  recentSearches,
  documentSuggestions,
}: SearchBarDropdownProps) => {
  return (
    <div className="bg-white shadow-lg rounded-b-[28px] pb-5 px-5">
      <div className="h-[1px] w-full bg-gray-200 mb-1" />
      {query.trim().length === 0 ? (
        <HistoryDropdown onOpenHistoryModal={onOpenHistoryModal} recentSearches={recentSearches} />
      ) : (
        <DocumentSuggestionsDropdown documentSuggestions={documentSuggestions} />
      )}
    </div>
  );
};
