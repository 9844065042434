import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const NotFound = () => {
  const { t } = useTranslation();

  return (
    <div className="flex flex-col items-center justify-center h-screen w-screen fixed top-0 left-0 bg-qura-bg">
      <div className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2 flex flex-col items-center w-full max-w-3xl px-3">
        <h1 className="text-2xl font-medium text-qura-neutral-jet mb-3">
          {t('errors.document404Title')}
        </h1>
        <p className="text-sm text-qura-neutral-balanced mb-6 text-center">
          {t('errors.document404Description')}
        </p>
        <Link
          to="/"
          className="bg-qura-neutral-jet text-white px-5 py-1.5 rounded-full text-sm hover:bg-qura-neutral-jet-medium transition-colors">
          {t('common.backToHome')}
        </Link>
      </div>
    </div>
  );
};

export default NotFound;
